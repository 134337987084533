import _ from 'lodash';
import { EventBus } from './event-bus';

export class EventBusService {
  /**
   * Gets the global event bus
   */
  static getEventBus() {
    // eslint-disable-next-line no-underscore-dangle
    EventBusService._initEventBus();
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return window['LWMS'].Events;
  }

  /**
   * Inits the event bus if it doesn't exist yet.
   */
  static _initEventBus() {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (!window.hasOwnProperty('LWMS') || !window['LWMS'].hasOwnProperty('Events')) {
      _.merge(window, {
        LWMS: {
          Events: new EventBus(),
        },
      });
    }
  }
}
